import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  qrImage: {
    width: "100%",
    height: "100%",
    maxWidth: 200,
    maxHeight: 200,
    objectFit: "contain",
  },
}));

const CollectorQrCode: React.FC<{
  colector: { qrCode: string };
  size?: number;
}> = ({ colector, size }) => {
  const classes = useStyles();
  const _size = size || 200;

  return (
    <img
      src={`data:image/png;base64,${colector.qrCode}`}
      alt="QR Code for collector"
      className={classes.qrImage}
    />
  );
};

export default CollectorQrCode;
