import React from "react";
import FlexContainer from "../../../components/containers/FlexContainer";
import { ModalBackdrop } from "../../../components/containers/modal/Modal/components/ModalBackdrop";
import { ModalDialog } from "../../../components/containers/modal/Modal/components/ModalDialog";
import Portal from "../../../components/containers/Portal";
import { Logo } from "../../../pages/login/components/LoginFormContainer";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { useSessionContext } from "../store/sessionContext";
import NavBarLogo from "./../../../assets/img/navBarLogo.png";
import { Box, Typography } from "@material-ui/core";
import LogOutButton from "../../../layout/components/LogOutButton";

const LoginModal: React.FC<{}> = ({}) => {
  const { lang } = useLangLabels();
  const { sessionStale } = useSessionContext();

  const actualTextDate = `${new Date().getFullYear() - 1990} ${lang.login.yearText} - ${new Date().getFullYear()}`;

  return (
    <Portal>
      <ModalBackdrop isOpen={sessionStale} zIndex={5000}>
        <ModalDialog
          isOpen={sessionStale}
          onClick={(e: any) => e.stopPropagation()}
          height={"auto"}
          width={"auto"}
          style={{ borderRadius: "8px", maxWidth: "400px" }}
        >
          <FlexContainer justify={"center"} height={"80px"} padding="12px 12px 2px 12px">
            <Logo src={NavBarLogo} />
          </FlexContainer>

          <FlexContainer
            padding={"12px"}
            style={{ flexDirection: "column", alignItems: "center", textAlign: "center" }}
          >
            <Typography variant="h6">{lang.login.modal.title}</Typography>
            <Typography variant="body1">{lang.login.modal.subTitle}</Typography>
            <Box style={{ margin: "10px 0px", textAlign: "center", width: "100%" }}>
              <LogOutButton />
            </Box>
            <p>TGD S.A © - {actualTextDate}</p>
          </FlexContainer>
        </ModalDialog>
      </ModalBackdrop>
    </Portal>
  );
};

export default LoginModal;
