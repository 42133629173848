import { Box, CircularProgress, Typography } from "@material-ui/core";
import ApartmentIcon from "@material-ui/icons/Apartment";
import { useNavigate } from "react-router-dom";
import IMG from "../../../assets/img/login-background.png";
import CustomButton from "../../../components/buttons/CustomButton";
import LogOutButton from "../../../layout/components/LogOutButton";
import LoginFormContainer, {
  LoginPageContainer,
} from "../../../pages/login/components/LoginFormContainer";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { usePlantContext } from "../../../shared/PlantContext/PlantContext";
import { USER_ROUTES } from "../../../shared/routes/routes";
import { usePlants } from "../../session/store/sessionStore";
import { Plant } from "../model/PlantInterface";
import { useSelectPlant } from "../services/plantsService";
import { useSessionContext } from "../../session/store/sessionContext";
import { useEffect } from "react";
import { logger } from "../../../utils/logs/logger";
import theme from "../../../assets/theme";

const PlantSelectionPage = () => {
  const plants = usePlants();
  const { selectedPlant, setSelectedPlant } = usePlantContext();
  const { tagFP, session } = useSessionContext();
  const navigate = useNavigate();
  const { lang } = useLangLabels();
  const { selectPlant } = useSelectPlant();

  const handlePlantSelected = async (plant: Plant) => {
    if (plant.tagFP === tagFP) {
      navigate(`/${USER_ROUTES.home}`);
    }

    setSelectedPlant(plant);
  };

  const selectNewPlant = async () => {
    try {
      await selectPlant({ tagFP });
      navigate(`/${USER_ROUTES.home}`);
    } catch (error) {
      logger.error(error, "Error al seleccionar la planta");
    }
  };

  useEffect(() => {
    if (tagFP && selectedPlant) {
      selectNewPlant();
    }
  }, [tagFP]);

  useEffect(() => {
    if (!tagFP && selectedPlant) {
      const currentPlant = session.plants.find(p => p?.tagFP === selectedPlant);
      if (currentPlant) {
        setSelectedPlant(currentPlant);
      }
    }
  }, [selectedPlant]);

  return (
    <>
      {selectedPlant ? (
        <LoginPageContainer color={theme.palette.secondary.main as string}>
          <CircularProgress size={"24px"} style={{ color: "white" }} />
          <br />
          {/* {!!token && ( */}
          <Typography color={"inherit"} style={{ color: "white" }}>
            {lang.plantSelection.loadingPlantData}
          </Typography>
          {/* )} */}
        </LoginPageContainer>
      ) : (
        <LoginFormContainer>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${IMG})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              filter: "grayscale(100%)",
              zIndex: -1,
            }}
          ></div>
          <Box style={{ textAlign: "center", margin: "16px 0" }}>
            {session.currentPlant?.name ? (
              <Typography variant="h6" component="h1" color="secondary">
                {lang.plantSelection.actualPlant} {session.currentPlant?.name}
              </Typography>
            ) : (
              <Typography variant="h6" component="h1" color="secondary">
                {lang.plantSelection.selectPlant}
              </Typography>
            )}
          </Box>
          <form
            style={{
              paddingTop: "12px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              rowGap: "12px",
            }}
          >
            {plants.map((plant, i) => (
              <CustomButton
                key={i}
                color="secondary"
                styleProps={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "8px",
                  padding: "8px 16px",
                }}
                action={() => handlePlantSelected(plant)}
              >
                <ApartmentIcon style={{ fontSize: "20px" }} />
                <div style={{ flexGrow: 1, textAlign: "center" }}>{plant.name}</div>
              </CustomButton>
            ))}
          </form>
          <Box style={{ margin: "10px 0px", textAlign: "center" }}>
            <LogOutButton />
          </Box>
        </LoginFormContainer>
      )}
    </>
  );
};

export default PlantSelectionPage;
