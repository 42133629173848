import React, { useEffect, useState } from "react";
import { Button, TextField, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { LubricationPointInterface } from "../../../../../../lubricationPoints/model/lubricationPointsInterface";
import { TaskType } from "../../../../../tasks/model/TaskInterface";
import { QueryStatus } from "react-query";
import { LubricantInterface } from "../../../../../../lubricant/model/LubricantInterface";
import { useAllLubricantsByPlant } from "../../../../../../lubricant/services/service.lubricants";
import { EquipmentFormRegex, TypeFormRegex } from "../../../../../../../shared/regExp/regExp";
import { useLubricationPointSurveyController } from "../../../../../services/controllers/useLubricationPointSurveyController";
import { suppliesToString } from "../../../../../../supply/utils/supplyToString";
import { ObservationInterface } from "../../../../../../observations/models/ObservationTypes";
import { bakupAddSurveyWithObservations } from "../../../../../../../shared/dexie-js/backup/backupRepository";
import { errorsLog } from "../../../../../../../shared/globals/utils/utils";
import CustomButton from "../../../../../../../components/buttons/CustomButton";
import PageContainer from "../../../../../../../components/containers/PageContainer";
import FlexContainer from "../../../../../../../components/containers/FlexContainer";
import SelectLubricantBy from "../../../../../../lubricant/molecules/SelectLubricantBy";
import LubricantQuantityCalculator from "./LubricantQuantityCalculator";
import SelectAddedReason from "../../../../../../added-reason/components/SelectAddedReason";
import AddSupply from "../../../../../../supply/molecules/AddSupply";
import AddObservation from "../../../../../../observations/components/AddObservation";
import TgdFab from "../../../../../../../components/buttons/TgdFab";
import { useSessionContext } from "../../../../../../session/store/sessionContext";
import { useDate } from "../../../../../../../shared/date/useDate";
import { Container } from "react-dom";
import { CustomIcon } from "../../../../../../../components/buttons/CustomIcon";
import { useLangLabels } from "../../../../../../../shared/lang/services/useLangLabels";
import { logger } from "../../../../../../../utils/logs/logger";

interface SurveyLubricationProps {
  item: LubricationPointInterface;
  taskType: TaskType;
  statusController: (status: QueryStatus) => void;
  outOfRoute: boolean;
}

const SurveyLubrication: React.FC<SurveyLubricationProps> = ({
  statusController,
  item,
  taskType,
  outOfRoute,
}) => {
  const { lang } = useLangLabels();

  // Component State
  const tagFP = item.tagFP;
  const { user } = useSessionContext();
  const { register, handleSubmit, errors, watch, setValue } = useForm();
  const { isToday } = useDate();

  const [step, setStep] = useState<any>();
  const [isBomb, setIsBomb] = useState(false);
  const [computedQuantity, setComputedQuantity] = useState(0);
  const [selectedContainer, setSelectedContainer] = useState<Container>();

  // Survey Data
  const {
    survey,
    backTaskStep,
    nextTaskStep,
    setLubricate,
    setImpossibleToLubricate,
    setAddedReason,
    setLubricant,
    setQuantity,
    setTaskDone,
    setTaskTime,
    createSurvey,
    updateEquipmentInfo,
    createObservation,
  } = useLubricationPointSurveyController(item, outOfRoute);

  const observations = survey?.observations || [];
  const anomalies = survey?.anomalies || [];
  const taskDone = survey?.lubrication.taskDone;
  const taskTime = survey?.lubrication.taskTime;
  const addedReason = survey?.lubrication.addedReason;
  const lubricant = survey?.lubrication.lubricant;
  const quantity = survey?.lubrication.quantity;
  const needsLubrication = survey?.inspection.needsLubrication;
  const impossibleToLubricate = !!survey?.lubrication.impossibleToLubricate;
  const supplies = survey?.neededSupplies || [];
  const lubricates = survey?.lubrication.lubricates;
  const startTime = survey?.inspection.startTime;

  const requiredObservation = useAllLubricantsByPlant().data?.find(
    (l: LubricantInterface) => l.lubricant === (lubricant || "")
  )?.requiresObservation;

  // Actions
  useEffect(() => {
    register({ name: "taskDone", value: taskDone });
    register("taskTime", {
      required: { value: !!lubricates, message: lang.validations.messages.required },
      pattern: {
        value: EquipmentFormRegex.integer,
        message: lang.validations.messages.justNumbers,
      },
      validate: !!lubricates
        ? value => (value > 0 ? true : lang.validations.messages.required)
        : undefined,
    });
    register("addedReason", {
      required: { value: !!lubricates, message: lang.validations.messages.required },
    });
    register("selectedContainer", {
      required: { value: !!lubricates && isBomb, message: lang.validations.messages.required },
    });
    register("lubricant", {
      required: { value: !!lubricates, message: lang.validations.messages.required },
    });
    register(
      { name: "quantity", type: "number" },
      {
        required: { value: !!lubricates, message: lang.validations.messages.required },
        validate: !!lubricates
          ? value => (value > 0 ? true : lang.validations.messages.required)
          : undefined,
      }
    );

    setValue("taskTime", taskTime);
    setValue("addedReason", addedReason);
    setValue("selectedContainer", selectedContainer);
    setValue("lubricant", lubricant);
    setValue("quantity", quantity);
  }, [taskTime, taskDone, addedReason, selectedContainer, isBomb, lubricant, quantity, lubricates]);

  useEffect(() => {
    if (!needsLubrication) {
      setTaskDone(taskType, true);
    }
  }, []);

  const getTaskDone = () => {
    if (needsLubrication === false) return true;
    if (impossibleToLubricate === true) return true;
    if (lubricates === true) return true;
    else return false;
  };

  const submit = async () => {
    statusController("loading");
    const { surveyDone: _surveyDone, ...restLubrication } = survey?.lubrication || {};
    const newSurvey = {
      ...survey?.inspection,
      ...restLubrication,
      startTime: survey?.inspection.startTime!,
      inaccessible: !!survey?.inspection.inaccessible,
      tagTGD: survey?.inspection.tagTGD!,
      tagFP: survey?.inspection.tagFP!,
      equipment: survey?.inspection.equipment!,
      sector: survey?.inspection.sector!,
      needsLubrication: !!survey?.inspection.needsLubrication,
      review: survey?.inspection.review!,
      route: survey?.inspection.route!,
      routeDate: survey?.inspection.routeDate!,
      measureUnit: survey?.inspection.measureUnit!,
      manualRegistration: !!survey?.inspection.manualRegistration,
      outOfRoute,
      taskType,
      lubricantType: survey?.lubrication.lubricantType!,
      sampleExtraction: !!survey?.lubrication.sampleExtraction,
      lubricates: !!survey?.lubrication.lubricates,
      impossibleToLubricate,
      taskTime: taskTime!.toString(),
      taskDone: getTaskDone(),
      reason: addedReason!,
      endTime: isToday(survey?.inspection.startTime!)
        ? new Date().toISOString()
        : survey?.inspection.startTime!,
      lubricant: lubricant!,
      quantity: isBomb ? computedQuantity : quantity,
      consumables: JSON.stringify(suppliesToString(supplies)),
      user: user?.email!,
    } as any;

    const newObservations = (id: number) => {
      return [
        ...anomalies.map((o: ObservationInterface) => ({ ...o, surveyId: id })),
        ...observations.map((o: ObservationInterface) => ({
          ...o,
          surveyId: id,
        })),
      ];
    };

    bakupAddSurveyWithObservations(newSurvey, newObservations);

    createSurvey(newSurvey)
      .then((surveyData: any) => {
        if (survey) {
          survey.lastSurveyId = newSurvey.lubricates ? surveyData.id : item.info?.lastSurveyId;
          survey.lubrication.quantity = newSurvey.lubricates
            ? surveyData.quantity
            : item.info?.lubricantQuantity;
          survey.lubrication.surveyDone = true;
        }

        createObservation(newObservations(surveyData.id)).then((observationData: any) => {
          if (!!observationData.error) {
            return statusController("error");
          } else {
            updateEquipmentInfo({
              ...item.info,
              ...survey?.inspection,
              user: surveyData.user,
              lubricantQuantity: survey?.lubrication.quantity,
              updatedToday: true,
              lastSurveyId: survey?.lastSurveyId,
              lastLubrication: !!survey?.lubrication.lubricates
                ? startTime!
                : item?.info?.lastLubrication!,
              lastInspectionDate: startTime!,
              updateReview:
                survey?.inspection.updateReview === false
                  ? item.info?.updateReview
                  : survey?.inspection.updateReview!,
              labelReplacement:
                survey?.inspection.labelReplacement === false
                  ? item.info?.labelReplacement
                  : survey?.inspection.labelReplacement!,
              pending: true,
            } as any).then(async (updateData: any) => {
              if (updateData.error) {
                return statusController("error");
              } else {
                statusController("success");
                nextTaskStep(taskType);
              }
            });
          }
        });
      })
      .catch(err => {
        statusController("error");
        logger.error(err, `SurveyLubrication -> createSurvey`);
      });
  };

  errorsLog(errors);

  return (
    <>
      <div>
        <CustomButton
          action={() => backTaskStep(taskType)}
          icon="before"
          variant="iconButton"
          title={lang.actionTypes.before}
        />
      </div>

      {needsLubrication === true && (
        <>
          <PageContainer>
            <FlexContainer>
              <Button
                color={lubricates ? "secondary" : "inherit"}
                variant="contained"
                onClick={() => {
                  setTaskDone(taskType, true);
                  setLubricate(true);
                  setStep(undefined);
                }}
              >
                {lang.surveys.lubricates} <CustomIcon icon="check" />
              </Button>

              <Button
                color={lubricates === false && !impossibleToLubricate ? "secondary" : "inherit"}
                variant="contained"
                onClick={() => {
                  setTaskDone(taskType, false);
                  setLubricate(false);
                  setStep(undefined);
                }}
              >
                {lang.surveys.doesNotLubricate} <CustomIcon icon="close" />
              </Button>

              <Button
                color={impossibleToLubricate ? "secondary" : "inherit"}
                variant="contained"
                onClick={() => {
                  setTaskDone(taskType, true);
                  setImpossibleToLubricate();
                  setStep(undefined);
                }}
              >
                {lang.surveys.impossibleToLubricate} <CustomIcon icon="close" />
              </Button>
            </FlexContainer>
          </PageContainer>

          {lubricates === true && (
            <>
              <PageContainer>
                <SelectLubricantBy
                  error={errors?.lubricant?.message}
                  tagFP={item.tagFP}
                  onChange={(value: string) => setLubricant(value)}
                  defaultValue={lubricant}
                  by={{
                    key: "type",
                    value: item.lubricantType,
                  }}
                />

                <br />

                <FlexContainer gap={"12px"}>
                  <TextField
                    name="quantity"
                    variant="outlined"
                    defaultValue={quantity}
                    onChange={e =>
                      setQuantity(e.target.value === "" ? 0 : parseFloat(e.target.value))
                    }
                    size="small"
                    style={{ width: "100%" }}
                    color={errors?.quantity?.message ? "primary" : "secondary"}
                    label={lang.surveys.quantity}
                    inputRef={register({
                      required: { value: true, message: lang.validations.messages.required },
                      pattern: {
                        value: TypeFormRegex.float,
                        message: lang.validations.messages.justNumbers,
                      },
                    })}
                  />

                  <LubricantQuantityCalculator
                    unit={survey?.inspection.measureUnit!}
                    quantity={watch("quantity") || 0}
                    computedQuantity={computedQuantity}
                    setComputedQuantity={setComputedQuantity}
                    onChange={(value: Container) => {
                      setSelectedContainer(value);
                    }}
                    lubricant={survey?.lubrication.lubricant!}
                    setIsBomb={setIsBomb}
                    error={errors?.selectedContainer?.message}
                  />
                </FlexContainer>

                <br />

                <TextField
                  name="taskTime"
                  variant="outlined"
                  defaultValue={taskTime}
                  size="small"
                  onChange={event => {
                    setTaskTime(
                      taskType,
                      JSON.parse(event.target.value !== "" ? event.target.value : "0")
                    );
                  }}
                  style={{ width: "100%" }}
                  color={errors?.taskTime?.message ? "primary" : "secondary"}
                  label={lang.surveys.taskTime}
                  inputRef={register()}
                  helperText={lang.surveys.inMinutes}
                />

                <br />
                <br />

                <SelectAddedReason
                  tagFP={tagFP}
                  onChange={(value: any) => {
                    setAddedReason(value);
                  }}
                  error={errors?.addedReason?.message}
                  defaultValue={addedReason}
                />
              </PageContainer>

              <AddSupply
                outOfRoute={outOfRoute}
                listTitle={lang.supplies.title.plural}
                formTitle={lang.actionTypes.new + " " + lang.supplies.title.singular}
                buttonTitle={lang.supplies.title.plural}
                variant={"list"}
                buttonType={"add"}
                lubricationPoint={item}
              />

              {!requiredObservation ? (
                <FlexContainer width="100%" align="center" justify="flex-end">
                  {!step?.observation && observations?.length === 0 && (
                    <CustomButton
                      icon="close"
                      title={lang.words.without + " " + lang.observations.plural}
                      action={() => setStep({ observation: lang.surveys.tasks.noObservations })}
                    />
                  )}
                  <AddObservation
                    outOfRoute={outOfRoute}
                    taskType={taskType}
                    lubricationPoint={item}
                    type="OBSERVACION"
                    buttonTitle={lang.observations.plural}
                    formTitle={lang.actionTypes.add + " " + lang.observations.singular}
                    variant={step?.observation || observations?.length !== 0 ? "list" : "modal"}
                    listTitle={step?.observation || lang.observations.plural}
                    buttonType="add"
                  />
                </FlexContainer>
              ) : (
                <AddObservation
                  outOfRoute={outOfRoute}
                  taskType={taskType}
                  lubricationPoint={item}
                  type="OBSERVACION"
                  buttonTitle={lang.observations.plural}
                  formTitle={lang.observations.singular}
                  variant={observations.length > 0 ? "list" : "form"}
                  listTitle={lang.observations.plural}
                  buttonType="add"
                />
              )}
            </>
          )}

          {lubricates === false && !impossibleToLubricate && (
            <>
              <AddSupply
                outOfRoute={outOfRoute}
                listTitle={lang.supplies.title.plural}
                formTitle={lang.actionTypes.new + " " + lang.supplies.title.singular}
                buttonTitle={lang.supplies.title.plural}
                variant={"list"}
                buttonType={"add"}
                lubricationPoint={item}
                disableUsedSupplies={true}
              />

              <AddObservation
                listTitle={lang.observations.plural}
                outOfRoute={outOfRoute}
                taskType={taskType}
                type="NO LUBRICA"
                lubricationPoint={item}
                variant={observations.length > 0 ? "list" : "content"}
                formTitle={lang.surveys.whyDontYouLubricate}
                buttonType="add"
              />
            </>
          )}

          <TgdFab
            disabled={!(step?.observation || observations?.length !== 0)}
            bottom={4}
            right={4}
            color="primary"
            onClick={handleSubmit(submit)}
            children={<CustomIcon icon="next" />}
          />

          {impossibleToLubricate === true && (
            <>
              <AddObservation
                outOfRoute={outOfRoute}
                taskType={taskType}
                type="IMPOSIBLE DE LUBRICAR"
                lubricationPoint={item}
                listTitle={lang.observations.plural}
                variant="content"
                formTitle={lang.surveys.whyWasntItPossible}
                buttonType="add"
              />

              <PageContainer>
                <AddSupply
                  outOfRoute={outOfRoute}
                  listTitle={lang.infoLubricationPoints.supplies}
                  formTitle={lang.actionTypes.new + " " + lang.supplies.title.singular}
                  buttonTitle={lang.lubricationPoints.supplies}
                  variant={supplies?.length > 0 ? "list" : "modal"}
                  buttonType={"add"}
                  lubricationPoint={item}
                />
              </PageContainer>

              <TgdFab
                disabled={!(step?.observation || observations?.length !== 0)}
                bottom={4}
                right={4}
                color="primary"
                onClick={handleSubmit(submit)}
                children={<CustomIcon icon={"next"} />}
              />
            </>
          )}
        </>
      )}

      {needsLubrication === false && (
        <>
          <PageContainer>
            <Typography>El punto no necesita ser Lubricado</Typography>
          </PageContainer>

          <FlexContainer width="100%" align="center" justify="flex-end">
            <br />
            {!step?.observation && observations?.length === 0 && (
              <CustomButton
                icon="close"
                title={lang.words.without + " " + lang.observations.plural}
                action={() => setStep({ observation: lang.surveys.tasks.noObservations })}
              />
            )}

            <AddObservation
              outOfRoute={outOfRoute}
              taskType={taskType}
              lubricationPoint={item}
              type="OBSERVACION"
              buttonTitle={lang.observations.plural}
              formTitle={lang.actionTypes.add + " " + lang.observations.singular}
              variant={step?.observation || observations?.length !== 0 ? "list" : "modal"}
              listTitle={step?.observation || lang.observations.plural}
              buttonType="add"
            />

            {(step?.observation || observations?.length !== 0) && (
              <TgdFab
                bottom={3}
                right={4.5}
                color="primary"
                onClick={submit}
                children={<CustomIcon icon={"next"} />}
              />
            )}
          </FlexContainer>
        </>
      )}
    </>
  );
};

export default React.memo(SurveyLubrication);
