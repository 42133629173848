import { Outlet, useNavigate } from "react-router-dom";
import { useSessionContext } from "../../../domains/session/store/sessionContext";
import { useEffect } from "react";

interface ProtectedRouteProps {
  allowedRoles: string[];
}

const ProtectedRoute = ({ allowedRoles }: ProtectedRouteProps) => {
  const { person } = useSessionContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!person || !allowedRoles.includes(person.role)) {
      navigate(-1);
    }
  }, [person, allowedRoles, navigate]);

  if (person && allowedRoles.includes(person.role)) {
    return <Outlet />;
  }

  return <></>;
};

export default ProtectedRoute;
