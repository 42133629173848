import { CreateSurveyInterface } from "../../../../domains/surveys/model/SurveyInterface";
import { logger } from "../../../../utils/logs/logger";
import { getViewDateDay } from "../../../date/utils";
import { backupDb } from "../backup-db";

export const bakupAddSurveys = async (survey: CreateSurveyInterface) => {
  try {
    return await backupDb.surveys.add({
      ...survey,
      backupDate: getViewDateDay(new Date().toISOString()),
    });
  } catch (err: any) {
    if (err.name === "QuotaExceededError") {
      logger.warn(err, "surveyRepository -> bakupAddSurveys");
      await backupDb.cleanUpOldData(25);

      return await backupDb.surveys.add({
        ...survey,
        backupDate: getViewDateDay(new Date().toISOString()),
      });
    } else {
      logger.error(err, `surveyRepository -> bakupAddSurveys`);
      throw err;
    }
  }
};
