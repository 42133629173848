import React from "react";
import FlexContainer from "../../components/containers/FlexContainer";
import { SOFT_VERSION } from "../../config";

const VersionContainer: React.FC<{ company: string }> = ({ company, children }) => {
  const soft = SOFT_VERSION?.filter(item => item[0] === company)[0] || ["...", "0.0.0", "..."];

  const softName = soft[2];
  const softVersion = soft[1];

  return (
    <FlexContainer flexDirection="column" padding="0" style={{ position: "relative" }}>
      {children && children}
      <br />
      <div style={{ flexGrow: 1 }}>
        <span style={{ fontSize: "12px" }}>
          {softName} - {softVersion}
        </span>
      </div>
    </FlexContainer>
  );
};

export default VersionContainer;
