import { ObservationInterface } from "../../../../domains/observations/models/ObservationTypes";
import { logger } from "../../../../utils/logs/logger";
import { getViewDateDay } from "../../../date/utils";
import { backupDb } from "../backup-db";

export const backupAddMultipleObservations = async (observations: ObservationInterface[]) => {
  try {
    return await backupDb.observations.bulkAdd(
      observations.map(obs => ({
        ...obs,
        backupDate: getViewDateDay(new Date().toISOString()),
      }))
    );
  } catch (err: any) {
    if (err.name === "QuotaExceededError") {
      logger.warn(err, "observationRepository -> backupAddMultipleObservations");
      await backupDb.cleanUpOldData(25);

      return await backupDb.observations.bulkAdd(
        observations.map(obs => ({
          ...obs,
          backupDate: getViewDateDay(new Date().toISOString()),
        }))
      );
    } else {
      logger.error(err, `observationRepository -> backupAddMultipleObservations`);
      throw err;
    }
  }
};
