export const DEV_MODE = process.env.NODE_ENV === "development";
export const ONLINE_MODE = JSON.parse(process.env.REACT_APP_ONLINE_MODE);
export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
export const PBI_URL = process.env.REACT_APP_PBI_URL;
export const PBI_TOKEN_URL = process.env.REACT_APP_PBI_TOKEN_URL;

export const SOFT_NAME = process.env.REACT_APP_SOFT_NAME;

export const MODES = {
  LUBRICATION: "lubrication",
  INSTRUMENTS: "instruments",
};

export const MODE = MODES.LUBRICATION;

export const SOFT_VERSION = [
  ["TGD", "4.0.0.0", SOFT_NAME],
  ["STI", "1.3.1", "STI SENSORING"],
];
